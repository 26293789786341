import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
import { SiteTitle, Navigation, NavigationLink } from '../components/Header';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <div className="grid-container">
  <div className="heading">
    <div className="heading-navigation">
      <Navigation>
        <NavigationLink activeClassName="active" theme={{
                primary: 'green'
              }} order="2" key="key" to="/">key</NavigationLink>
      </Navigation>
    </div>
    <div className="heading-title">
      <div className="heading-title-text"><SiteTitle>The Digital Cooperative<br /> Development Consortium</SiteTitle></div>
      <div className="heading-title-quickinfo">info</div>
    </div>
  </div>
  <div className="content">
    <div className="content-col1">col1</div>
    <div className="content-col2">col2</div>
    <div className="content-col3">col3</div>
    <div className="content-col4">col4</div>
  </div>
  <div className="footer">footer</div>
      </div>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    